// eslint-disable-next-line semi
import { HttpClient } from '@angular/common/http';
// eslint-disable-next-line semi
import { Injectable } from '@angular/core';
// eslint-disable-next-line semi
import { ServicesUrls } from 'app/@core/servicesUrls';
// eslint-disable-next-line semi
import { Observable } from 'rxjs';
// eslint-disable-next-line semi
import { ClientDataForm, ClientDataFormContact } from './client-data.component';
import {
  ClientData,
  ClientDataAddressType,
  ClientDataContact,
  ClientDataContactPhone,
  ClientDataPhoneType,
  ClientDataPhone,
  ClientDataAddress,
  ClientDataUpgrade,
  RolesCRM,
  ContactData,
  BloqueioCadastroInfo,
  SaasInfo,
  ServiceNowInfo,
  UrlFlowReset
  // eslint-disable-next-line semi
} from './client-data.model';
// eslint-disable-next-line semi
import { ptBRtoEn } from '@shared/FormatDate';
// eslint-disable-next-line semi
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClientDataService {
  constructor(private http: HttpClient) {}

  getClientData(clientId: string): Observable<ClientDataUpgrade> {
    return this.http.post<ClientDataUpgrade>(`${ServicesUrls.CLIENT_AREA}/queries/getClient`, {
      id: clientId
    });
  }

  getFlowReset(clientId: string): Observable<UrlFlowReset> {
    return this.http.post<UrlFlowReset>(`${ServicesUrls.CLIENT_AREA}/queries/getFlowReset`, {
      id: clientId
    });
  }

  getSaas(clientId: string): Observable<SaasInfo> {
    return this.http.post<SaasInfo>(`${ServicesUrls.CLIENT_AREA}/queries/getSaas`, {
      id: clientId
    });
  }

  getServiceNowProject(clientId: string): Observable<ServiceNowInfo> {
    return this.http.post<ServiceNowInfo>(`${ServicesUrls.CLIENT_AREA}/queries/getServiceNowProject`, {
      id: clientId
    });
  }

  saveClientData(clientData: ClientData): Observable<{ result: boolean }> {
    return this.http.post<{ result: boolean }>(
      `${ServicesUrls.CLIENT_AREA}/actions/registrationUpdate`,
      clientData
    );
  }

  saveContactData(contactData: ContactData): Observable<{ result: boolean }> {
    return this.http.post<{ result: boolean }>(
      `${ServicesUrls.CLIENT_AREA}/actions/registrationUpdateContacts`,
      contactData
    );
  }

  parseAddresses(addresses: ClientDataAddress[]): ClientDataAddress[] {
    if (addresses && addresses.length > 1) {
      return addresses.filter(el => el.type !== ClientDataAddressType.COMERCIAL);
    }

    return [];
  }

  parsePhones(phone1, phone2, phones): ClientDataPhone[] {
    const companyPhones: ClientDataPhone[] = [
      {
        id: phone1.id,
        phone: phone1.phone,
        type: ClientDataPhoneType.COMERCIAL
      }
    ];
    if (phone2 && phone2.phone) {
      companyPhones.push({
        id: phone2.id,
        phone: phone2.phone,
        type: ClientDataPhoneType.COMERCIAL
      });
    }

    if (phones && phones.length > 2) {
      phones.slice(2).map(el =>
        companyPhones.push({
          id: el.id,
          type: ClientDataPhoneType.COMERCIAL,
          phone: el.phone
        })
      );
    }

    return companyPhones;
  }

  public parseContactFromFormBuilder(form: ContactData): ContactData {
    const { contacts } = form;
    const clientContacts: ClientDataContact[] = this.parseClientContacts(contacts);

    return { contacts: clientContacts };
  }

  public parseFromFormBuilder(form: ClientDataForm): ClientData {
    const {
      id,
      companyName,
      trade,
      cpfCnpj,
      integrationCode,
      stateRegistration,
      municipalRegistration,
      billingEmail,
      legalDocumentEmail,
      contractEmail,
      website,
      phone1,
      phone2,
      phones,
      address,
      addresses,
      allowed
    } = form;

    const clientPhones = this.parsePhones(phone1, phone2, phones);
    const clientAddresses: ClientDataAddress[] = [
      {
        id: address.id,
        type: ClientDataAddressType.COMERCIAL,
        cpfCnpj,
        zipCode: address.zipCode ? address.zipCode : null,
        address: address.address,
        number: address.number,
        complement: address.complement,
        neighborhood: address.neighborhood,
        landmark: address.landmark,
        city: address.city,
        state: address.state,
        stateRegistration,
        municipalRegistration
      }
    ];
    clientAddresses.push(...this.parseAddresses(addresses));

    return {
      id,
      companyName,
      trade,
      integrationCode,
      billingEmail,
      legalDocumentEmail,
      contractEmail,
      website,
      allowed,
      addresses: clientAddresses,
      phones: clientPhones
    };
  }

  parseClientContacts(contacts: ClientDataFormContact[]): ClientDataContact[] {
    const parsed = contacts.map(contact => {
      const phones = contact.phones
        .map(({ id, phone, phoneExtension }) => {
          return {
            id,
            type: ClientDataPhoneType.COMERCIAL,
            phone,
            phoneExtension: phoneExtension === '' ? undefined : phoneExtension
          } as ClientDataContactPhone;
        })
        .filter(({ phone }) => phone && phone !== '');

      return {
        id: contact.id,
        name: contact.name,
        birthDate: ptBRtoEn(contact.birthDate) || undefined,
        department: contact.department,
        role: contact.role,
        email: contact.email,
        status: contact.status,
        phones
      };
    });

    return parsed.filter(el => el.name && el.email);
  }

  confirmContact(clientId: string): Observable<ClientDataUpgrade> {
    return this.http.post<ClientDataUpgrade>(`${ServicesUrls.CLIENT_AREA}/actions/confirmContact`, {
      id: clientId
    });
  }

  getRolesCRM(): Observable<RolesCRM[]> {
    return this.http.get(`${ServicesUrls.CLIENT_AREA}/queries/getRolesCRM`).pipe(pluck('roles'));
  }

  getDepartmentsCRM(): Observable<RolesCRM[]> {
    return this.http.get(`${ServicesUrls.CLIENT_AREA}/queries/getDepartmentsCRM`).pipe(pluck('departments'));
  }
}
